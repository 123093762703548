<template>
  <div class="apply">
    <div class="head">
      <i class="el-icon-s-claim"></i>
      <span>我的报名</span>
    </div>

    <div class="content-panel">
      <div class="filter-wrap">
        <div class="type">
          <el-button style="width: 100px" size="mini" type="primary"
            >赛事</el-button
          >
          <el-button style="width: 100px" size="mini">活动</el-button>
          <el-button style="width: 100px" size="mini">人才</el-button>
        </div>
        <div class="select">
          <span style="margin-right: 4px">报名状态：</span>
          <el-select placeholder="请选择" size="medium">
            <!-- <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option> -->
          </el-select>

          <el-input
            style="width: 250px; margin: 0 4px"
            placeholder="请输入大赛名称"
            size="medium"
          />

          <el-link :underline="false">查询</el-link>
        </div>
      </div>

      <div class="table-wrap">
        <el-table
          size="mini"
          ref="multipleTable"
          :data="[]"
          tooltip-effect="dark"
          style="width: 100%"
          stripe
          header-row-class-name="table-head-row"
        >
          <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
          <el-table-column label="赛事名称"> </el-table-column>

          <el-table-column prop="name" label="报名状态" width="120">
          </el-table-column>

          <el-table-column prop="name" label="项目名称" width="120">
          </el-table-column>

          <el-table-column prop="name" label="操作" width="120">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.apply {
  .head {
    display: flex;
    align-items: center;
    background: #ffffff;
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    padding: 0px 25px;
    margin-bottom: 12px;
    font-weight: bold;
    vertical-align: middle;

    i {
      font-size: 18px;
      margin-right: 4px;
      vertical-align: middle;
    }
  }

  .content-panel {
    background: #ffffff;
    overflow: hidden;
    padding: 25px 25px 25px 25px;
    min-height: 650px;
    position: relative;

    .filter-wrap {
      .type {
        margin-bottom: 20px;
      }

      .select {
        font-size: 14px;
      }
    }

    .table-wrap {
      margin-top: 18px;
      ::v-deep .table-head-row th {
        background: #f4f5f9;
        color: #666;
      }
    }
  }
}
</style>